// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/root.tsx");
}
// REMIX HMR END

import React from 'react';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useRouteError, useFetcher } from '@remix-run/react';
// @ts-ignore module exists
import { ExternalScripts } from 'remix-utils/external-scripts';
import { json, redirect } from '@remix-run/server-runtime';
import { cssBundleHref } from '@remix-run/css-bundle';
// or cloudflare/deno
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDebouncedCallback } from 'use-debounce';
import { getAlertServer } from '~common/components/alert/server/index.server';
import { AlertContainer, showAlert } from '~common/components/alert';
import { Layout } from '~admin/components/layout/Layout';
import { APP_META_DESCRIPTION, APP_META_TITLE } from '~admin/constants';
import { getActiveAdministratorDetails } from '~admin/providers/administrator/provider';
import { setApiUrl } from '~admin/graphqlWrapper';
import { ErrorMessage } from '~ui/Messages/ErrorMessage';
import { getTimezone, setTimezone } from '~common/components/alert/server/default-session.server';
import './sessions.server';
import { getActiveUser, removeActiveUser, setActiveUser } from './modules/channel/fetchers/cookie.server';
// import { WSMessage, useSocket } from '~common/hooks/socket/useSocket'
import styles from './styles/app.css';
import { activeChannel } from './providers/channel/provider';
import { settings } from './providers/global-settings/provider';
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  console.log('Error boundary reached for root:' + JSON.stringify(error));

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return <html lang="en" id="app">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <title>MAOS Supported Application</title>
          <Links />
        </head>
        <body className="dark:bg-slate-700 dark:text-slate-400">
          <main className="h-screen flex items-center justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





              <p>Status: {error.status}</p>
              <p>{error.data.message}</p>
            </div>
          </main>
          <Scripts />
          <ExternalScripts />
        </body>
      </html>;
  } else {
    if (error instanceof Error) {
      console.log(`General error: ${error.message}`);
    } else {
      console.log('Not a routing error:' + JSON.stringify(error));
    }
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  const errorMessage = 'Unknown error';
  // if (isDefinitelyAnError(error)) {
  //   errorMessage = error.message;
  // }

  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>MAOS Supported Application</title>
        <Links />
      </head>
      <body className="dark:bg-slate-700 dark:text-slate-400">
        <main className="h-screen flex items-center justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





          </div>
        </main>
        <Scripts />
      </body>
    </html>;
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: styles
}, {
  rel: 'stylesheet',
  href: cssBundleHref
}] : [])
// ...
];
export const meta = ({
  data
}) => {
  const metaTitle =
  // data?.activeChannel.customFields?.metaTitle ||
  // data?.activeChannel.customFields?.storeTitle ||
  APP_META_TITLE;
  const metaDescription =
  // data?.activeChannel.customFields?.metaDescription ||
  // data?.activeChannel.customFields?.storeDescription ||
  APP_META_DESCRIPTION;
  return [{
    title: metaTitle
  }, {
    name: 'description',
    content: metaDescription
  }];
};
const devMode = typeof process !== 'undefined' && process.env.NODE_ENV === 'development';
export async function loader({
  request,
  params,
  context
}) {
  const alertServer = getAlertServer();
  const {
    alert,
    headers
  } = await alertServer.getAlert(request);
  if (typeof context.VENDURE_API_URL === 'string') {
    // Set the API URL for Cloudflare Pages
    setApiUrl(context.VENDURE_API_URL);
  }
  let activeAdmin;
  let channelToken;
  let globalSettings;
  let channel = null;
  let wsUrl;
  try {
    const [{
      activeAdministrator
    }, activeUser] = await Promise.all([getActiveAdministratorDetails({
      request
    }), getActiveUser(request)]);
    if (activeUser) {
      channelToken = activeUser.get('channelToken');
    }
    if (activeAdministrator) {
      const settingsRes = await settings({
        request
      });
      globalSettings = settingsRes.globalSettings;
    }
    wsUrl = `${globalSettings?.customFields?.gotifyWebsocketUrl ?? ''}/stream?token=${channelToken?.messagingClientToken ?? ''}`;
    // during sign-in, we cannot set the channel messaging info. So, we have to do here once
    if (activeAdministrator) {
      activeUser.set('activeUserId', activeAdministrator.user.id);
      const {
        activeChannel: currentChannel
      } = await activeChannel({
        request
      });
      if (currentChannel) {
        const messagingClientToken = currentChannel.customFields?.gotifyClientToken ?? null;
        const channelTokenObj = {
          name: currentChannel.code,
          token: currentChannel.token,
          avatar: currentChannel.customFields?.storeIcon?.preview ? currentChannel.customFields?.storeIcon?.preview : undefined,
          messagingClientToken: messagingClientToken
        };
        activeUser.set('channelToken', channelTokenObj);
        // activeUser.set('channelToken', channelTokenObj)
        // const activeUserCookie = await setActiveUser(activeUser)
        // headers.append('Set-Cookie', activeUserCookie)
      }
      const activeUserCookie = await setActiveUser(activeUser);
      headers.append('Set-Cookie', activeUserCookie);
      channel = currentChannel;
    }
    activeAdmin = activeAdministrator;
  } catch (err) {
    console.log('Unexpected error. Redirecting to login', err.message);
    const url = new URL(request.url);
    const queryParams = Object.fromEntries(url.searchParams);
    const isRedirect = queryParams.is_redirect;
    if (url.pathname.startsWith('/logout')) {
      // we're in a recursion
      console.log('Error on logout call', err);
      return json({
        settings: globalSettings,
        activeAdministrator: activeAdmin,
        channel,
        wsUrl,
        alert: alert
      }, {
        headers
      });
    }
    // we have some kind of unexpected issue. Time to clear the channel and login again
    const cookie = await removeActiveUser(request);
    if (isRedirect) {
      console.log('Redirect look detected. Logging out.');
      return redirect('/logout', {
        headers: {
          'Set-Cookie': cookie
        }
      });
    }
    url.searchParams.set('is_redirect', 'true');
    console.log('Removing channel cookie and trying to continue.');
    return redirect(`/?${url.searchParams.toString()}`, {
      headers: {
        'Set-Cookie': cookie
      }
    });
  }
  const url = new URL(request.url);
  if (!activeAdmin && !(url.pathname.startsWith('/sign-in') || url.pathname.startsWith('/login-link') || url.pathname.startsWith('/oauth2') || url.pathname.startsWith('/forgot'))) {
    console.log('No user, redirecting to login');
    return redirect('/sign-in', {
      headers
    });
  }
  if (channel) {
    const session = await getTimezone(request);
    if (session.get('timezone') !== channel?.customFields?.timezone) {
      const ck = await setTimezone(request, channel?.customFields?.timezone ?? '');
      headers.append('Set-Cookie', ck);
    }
  }
  return json({
    settings: globalSettings,
    activeAdministrator: activeAdmin,
    channel,
    wsUrl,
    alert: alert
  }, {
    headers
  });
}
export default function App() {
  _s2();
  const {
    alert,
    activeAdministrator,
    channel,
    wsUrl
  } = useLoaderData();
  const handleMessage = React.useCallback(message => {
    const {
      data
    } = message;
    if (data) {
      const {
        appid,
        title,
        message
      } = JSON.parse(data);
      showAlert({
        type: 'success',
        // @ts-ignore ok-is
        title,
        message
      });
    }
  }, []);
  // const { connected } = useSocket({
  //   url: wsUrl,
  //   onMessage: handleMessage,
  // })

  const {
    readyState
  } = useWebSocket(wsUrl, {
    onOpen: () => console.log('opened'),
    shouldReconnect: closeEvent => !!wsUrl?.length,
    onMessage: handleMessage
  }, !!activeAdministrator);
  const ping = useDebouncedCallback(alt => {
    if (alt) {
      // @ts-ignore this is ok
      showAlert(alt);
    }
  }, 300, {
    maxWait: 600,
    leading: true,
    trailing: false
  });
  React.useEffect(() => {
    if (alert) {
      ping(alert);
    }
  }, [alert]);
  const administrator = activeAdministrator ?? null;
  const accountFetcher = useFetcher({
    key: 'account-fetcher'
  });
  const handleSignout = React.useCallback(() => {
    accountFetcher.submit({}, {
      action: `/api/auth/logout/`,
      method: 'POST'
    });
  }, []);
  return <html lang="en" id="app" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {/* <link rel="icon" href={favIcon} type="image/png"></link> */}
        {/* <title>{metaTitle}</title>
          {metaDescription && (
           <>
             <meta name="description" content={metaDescription} />
           </>
          )} */}
        <Meta />
        <Links />
      </head>
      <body className="h-full bg-white dark:bg-slate-800 dark:text-slate-400">
        <AlertContainer />
        {administrator ? <Layout administrator={administrator} channel={channel} onSignOut={handleSignout} socketActive={readyState === ReadyState.OPEN}>

            <Outlet context={{
          activeChannel: channel,
          user: administrator.user,
          permissions: administrator.user.roles.reduce((acc, val) => {
            return Array.from(new Set([...acc, ...val.permissions]));
          }, [])
        }} />

          </Layout> : <>
            <main className="">
              <Outlet context={{
            activeChannel: channel,
            user: null,
            permissions: []
          }} />

            </main>
            <ScrollRestoration />
          </>}

        <Scripts />
        <ExternalScripts />
        {devMode && <LiveReload />}
      </body>
    </html>;
}
_s2(App, "hfW/jjZml5CDrseiIp9AHhLe/8s=", false, function () {
  return [useLoaderData, useWebSocket, useDebouncedCallback, useFetcher];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;